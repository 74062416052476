import React, { useEffect, useState, MouseEvent  } from "react";
import axios, { AxiosError } from "axios";
import { useNavigate, useLocation } from 'react-router-dom';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Button, AppBar, CssBaseline, Toolbar, Typography, IconButton, Drawer, List, ListItem, ListItemIcon, ListItemText, Paper, Switch, Avatar, Menu, MenuItem, Modal, Box} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MailIcon from "@mui/icons-material/Mail";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import AccountCircle from '@mui/icons-material/AccountCircle';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import { styled } from "@mui/system";
import { DataGrid } from '@mui/x-data-grid';
import { DataGridPremium, GridRowModel, useGridApiRef, GridAggregationModel, GridColDef, useKeepGroupedColumnsHidden, GridInitialState, GridSortDirection } from '@mui/x-data-grid-premium';
import { PaletteMode } from '@mui/material';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Buffer } from 'buffer';
import { convertAndDownloadFile, getDataUrlFromArquivoContent } from './utils';
import box from './box';

interface RowData {  FIL_ORIG: string;  NR_PROCESSO: string;  ANO: string;  id?: string;  parentId?: string; documentos?: ChildData[]; children?: ChildData[];  NR_CAPA?: string; }
interface UserData { name: string; email: string; photo?: string;}
interface ChildData {  FIL_ORIG: string;  ANO: string;  NR_CAPA: string;  id: string;  parentId: string; }

// Define valueGetter function separately
const dateValueGetter = ({ value }: { value: string | undefined | null }) => value ? new Date(value) : null;

// Define valueFormatter function separately
const dateFormatter = ({ value }: { value: Date | null }) => {
  if (value instanceof Date && !isNaN(value.getTime())) {
    return new Intl.DateTimeFormat('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }).format(value);
  }
  return ''; // Return empty string for empty dates
};

// Define currencyValueGetter function separately
const currencyValueGetter = ({ value }: { value: string | undefined | null }) => {
  const number = Number(value);
  return isNaN(number) ? null : number;
};

// Define currencyFormatter function separately
const currencyFormatter = ({ value }: { value: number | null }) => {
  if (typeof value === 'number') {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  }
  return "N/A";
};

const getCapas: GridColDef[] = [
  { field: "id", headerName: "Capa", width: 120 },
//{ field: "CNPJ_PAG", headerName: "CNPJ Pag", width: 200 },
  { field: "REF_CLIENTE", headerName: "Ref Cliente", width: 220 },
  { field: "TP_SERVICO", headerName: "Tipo Servico", width: 220 },
  { field: "STATUS", headerName: "Status", width: 220 },
  { field: "DOCUMENTO", headerName: "Documento (BL/CRT/AWB)", width: 220 },
  { field: "D_I", headerName: "D.I / DUE", width: 150 },  
//{ field: "L_I", headerName: "L I", width: 150 },
  { field: "NOME", headerName: "Navio / Vôo", width: 220 },  
  { field: "ETA", headerName: "E. T. A.", width: 120 },  
  { field: "CHEGADA", headerName: "Atracação / Chegada", width: 160 }
];

const getCapas2 = [
  { field: "id", headerName: "Capa", width: 100 },
  { field: "CNPJ_PAG", headerName: "CNPJ Pagador", width: 180 },
  { field: "REF_CLIENTE", headerName: "Referência Cliente", width: 200 },
  { field: "DT_ABERTURA", headerName: "Data Abertura", width: 100, valueGetter: dateValueGetter, valueFormatter: dateFormatter }, 
  { field: "TP_SERVICO", headerName: "Tipo Serviço", width: 120 },
  { field: "STATUS", headerName: "Status", width: 100 },
  { field: "L_I", headerName: "L I", width: 150 },
  { field: "D_I", headerName: "D I", width: 150 },
//{ field: "NAVIO", headerName: "Navio", width: 150 },
//{ field: "E_T_A", headerName: "E T A", width: 150, type: 'date', valueGetter: (params) => params.row.E_T_A ? new Date(params.row.E_T_A) : null },
  { field: "HORA", headerName: "Hora", width: 100 },
  { field: "VIAGEM", headerName: "Viagem", width: 150 },
  { field: "TERMINAL", headerName: "Terminal", width: 150 },
  { field: "NAVIO_TRANSBORDO", headerName: "Navio Transbordo", width: 150 },
//{ field: "E_T_A_TRANSBORDO", headerName: "E T A Transbordo", width: 150, type: 'date' },
  { field: "HORA_TRANSBORDO", headerName: "Hora Transbordo", width: 100 },
  { field: "VIAGEM_TRANSBORDO", headerName: "Viagem Transbordo", width: 150 },
  { field: "TERMINAL_TRANSBORDO", headerName: "Terminal Transbordo", width: 150 },
  { field: "PRODUTO", headerName: "Produto", width: 150 },
  { field: "PESO", headerName: "Peso", width: 120, type: 'number' },
//{ field: "DT_ARMAZENAGEM", headerName: "Data Armazenagem", width: 150, type: 'date' },
  { field: "MOEDA", headerName: "Moeda", width: 150 },
  { field: "B_I_MASTER", headerName: "B/L Master", width: 150 },
  { field: "B_I_HOUSE", headerName: "B/L House", width: 150 },
  { field: "NR_RESERVA", headerName: "Nº Reserva", width: 150 },
  { field: "ORIGEM", headerName: "Origem", width: 150 },
  { field: "DESTINO", headerName: "Destino", width: 150 },
  { field: "DEPOSITARIO_ORIGEM", headerName: "Depositário Origem", width: 150 },
  { field: "DEPOSITARIO_DESTINO", headerName: "Depositário Destino", width: 150 },
  { field: "VOO_ORIGEM", headerName: "Voo Origem", width: 150 },
  { field: "VOO_DESTINO", headerName: "Voo Destino", width: 150 },

//{ field: "DT_VOO_ORIGEM", headerName: "Data Voo Origem", width: 150, type: 'date' },
//{ field: "DT_VOO_DESTINO", headerName: "Data Voo Destino", width: 150, type: 'date' },
  { field: "DT_VOO_ORIGEM", headerName: "Data Voo Origem", flex: 1, valueGetter: dateValueGetter, valueFormatter: dateFormatter },
  { field: "DT_VOO_DESTINO", headerName: "Data Voo Destino", flex: 1, valueGetter: dateValueGetter, valueFormatter: dateFormatter },

  { field: "A_W_B_MASTER", headerName: "AWB Master", width: 150 },
  { field: "A_W_B_HOUSE", headerName: "AWB House", width: 150 },
  { field: "VLR_MOEDA_C", headerName: "Valor Moeda Compra", width: 150, type: 'number' },
  { field: "VLR_MOEDA_V", headerName: "Valor Moeda Venda", width: 150, type: 'number' },
  { field: "IMPORTADOR", headerName: "Importador", width: 200 },
  { field: "EXPORTADOR", headerName: "Exportador", width: 200 },
  { field: "TRANSPORTADORA", headerName: "Transportadora", width: 150 },
  { field: "AGENCIA_MARITIMA", headerName: "Agência Marítima", width: 150 },
  { field: "ID_VEICULO", headerName: "ID Veículo", width: 120, type: 'number' },
  { field: "INCOTERMS", headerName: "Incoterms", width: 100 },
  { field: "DT_ORIGEM_VOO", headerName: "Data Origem Voo", width: 150 },
  { field: "DT_DESTINO_VOO", headerName: "Data Destino Voo", width: 150 },
//{ field: "FRONTEIRA", headerName: "Fronteira", width: 150 },
//{ field: "OPERADOR", headerName: "Operador", width: 150 },
//{ field: "DT_ATERACAO", headerName: "Data Alteração", width: 150, type: 'date' },
];

const getAduaneiro = [
//  { field: "DOCUMENTO_ADUANEIRO", headerName: "Documento Aduaneiro", width: 200 },
  { field: "FIL_ORIG", headerName: "Fil Orig", width: 100, hide: true },
  { field: "FIL_CAPA", headerName: "Filial Capa", width: 100, hide: true },
  { field: "NR_CAPA", headerName: "Número Capa", width: 100, hide: true },
  { field: "ANO", headerName: "Ano", width: 100, hide: true },  
  { field: "NM_TIPO_DOC", headerName: "Tipo documento", width: 500 }, //{ field: "TIPO_DOC", headerName: "Tipo Doc", width: 150 },
  { field: "NR_DOCUMENTO", headerName: "Nr Documento", width: 220 },
  { field: "VLR_MERCADORIA", headerName: "Vlr Mercadoria", width: 150, type: 'number' },
  { field: "MOEDA", headerName: "Moeda", width: 150 },
//{ field: "OPERADOR", headerName: "Operador", width: 200 },  
//{ field: "DT_ALTERACAO", headerName: "Data Alteração", width: 150, type: 'date' },
];

const getContainer = [
//{ field: "ENTRADA_CONTAINER", headerName: "Entrada Container", width: 200 },
  { field: "FIL_CAPA", headerName: "Filial Capa", width: 100, hide: true },
  { field: "NR_CAPA", headerName: "Número Capa", width: 100, hide: true },
  { field: "ANO", headerName: "Ano", width: 100, hide: true },
  { field: "NR_CONTAINER", headerName: "Nr Container", width: 200 },
  { field: "NM_TAM_CONTAINER", headerName: "Tamanho", width: 200 }, // { field: "TAM_CONTAINER", headerName: "Tamanho Container", width: 150 },
  { field: "NM_TIPO_ADUANEIRO", headerName: "Tipo Aduaneiro", width: 150 }, //{ field: "TIPO_ADUANEIRO", headerName: "Tipo Aduaneiro", width: 150 },
  { field: "TERM_DEVOLUCAO", headerName: "Terminal Devolução", width: 200 },
  { field: "LACRES", headerName: "Lacres", width: 200 },
  { field: "DT_DESOVA", headerName: "Data Desova", type: 'date', width: 100, valueGetter: dateValueGetter, valueFormatter: dateFormatter },
//{ field: "OPERADOR", headerName: "Operador", width: 200 },
//{ field: "DT_ALTERACAO", headerName: "Data Alteração", width: 150, type: 'date' },
];

const getCargaSolta = [
//  { field: "CARGA_SOLTA", headerName: "Carga Solta", width: 150 },
  { field: "FIL_CAPA", headerName: "Filial Capa", width: 100, hide: true },
  { field: "NR_CAPA", headerName: "Número Capa", width: 100, hide: true },
  { field: "ANO", headerName: "Ano", width: 100, hide: true },
  { field: "ESPECIE", headerName: "Espécie", width: 200 },
  { field: "VOLUMES", headerName: "Volumes", width: 150, type: 'number' },
  { field: "PESO", headerName: "Peso Bruto", width: 150, type: 'number' },
  { field: "PESO_LIQ", headerName: "Peso Líquido", width: 150, type: 'number' },
  { field: "PESO_CUB", headerName: "Peso Cubado", width: 150, type: 'number' },
  { field: "PRODUTO", headerName: "Produto", width: 200 },
//{ field: "OPERADOR", headerName: "Operador", width: 200 },
//{ field: "DT_ALTERACAO", headerName: "Dt. Alteração", width: 150, type: 'date' },
];

const getStatus = [
//{ field: "EVENTO_COMR", type: 'number', required: true },
  { field: "FIL_CAPA", headerName: "Filial Capa", width: 100, hide: true },
  { field: "NR_CAPA", headerName: "Número Capa", width: 100, hide: true },
  { field: "ANO", headerName: "Ano", width: 100, hide: true },
  { field: "EVENTO", headerName: "Evento", width: 500 },
  { field: "DT_INCLUCAO", headerName: "Data inclusão", width: 150, valueGetter: dateValueGetter, valueFormatter: dateFormatter },
  { field: "HORA_INCLUSAO", headerName: "Hora Inclusão", width: 200 },

//{ field: "ID_STATUS", width: 20 },
//{ field: "OPERADOR" },
//{ field: "DT_ALTERACAO", type: 'date' },
];

const getNumerarios: GridColDef[] = [
//{ field: "PEDIDO_NUMERARIO", headerName: "Pedido Numerário", width: 200, type: 'number' },
  { field: "FIL_CAPA", headerName: "Filial Capa", width: 100 },
  { field: "NR_CAPA", headerName: "Número Capa", width: 100, type: 'number' },
  { field: "ANO", headerName: "Ano", width: 100 },
  { field: "DESCRICAO", headerName: "Descrição", width: 500 }, 
  { field: "VLR_PEDIDO", headerName: "Valor Pedido (R$)", width: 170, availableAggregationFunctions: ['sum'], aggregable: true, groupable: false, valueGetter: currencyValueGetter, valueFormatter: currencyFormatter }, 
  { field: "DT_INCLUSAO", headerName: "Data inclusão", width: 180, valueGetter: dateValueGetter, valueFormatter: dateFormatter },  
//{ field: "DT_VENCIMENTO", headerName: "Data Vencimento", flex: 1, valueGetter: dateValueGetter, valueFormatter: dateFormatter },
//{ field: "DEBITO_DIRETO", headerName: "Débito Direto", width: 130, valueFormatter: (params) => params.value === 'F' ? 'Não' : 'Sim'},
//{ field: "OPERADOR", headerName: "Operador", width: 200 },
//{ field: "DT_ALTERACAO", headerName: "Data Alteração", width: 150, type: 'date' }, // Uncomment if you want to display this field
];

const getFaturamentos: GridColDef[] = [
//{ field: "FATURAMENTO", headerName: "Faturamento", width: 150, type: 'number' },
  { field: "FIL_CAPA", headerName: "Filial Capa", width: 100 },
  { field: "NR_CAPA", headerName: "Número Capa", width: 100, type: 'number' },
  { field: "ANO", headerName: "Ano", width: 100 },
  { field: "FIL_ORIG", headerName: "Filial Origem", width: 150 },
  { field: "NM_TIPO_DOC", headerName: "Tipo Documento", width: 500 }, // { field: "TIPO_DOC", headerName: "Tipo Documento", width: 150 },
//{ field: "NR_DOCUMENTO", headerName: "Número Documento", width: 200 },
//{ field: "BANCO", headerName: "Banco", width: 100 },
//{ field: "AGENCIA", headerName: "Agência", width: 150 },
//{ field: "CONTA", headerName: "Conta", width: 150 },

//  { field: "DC", headerName: "Débito/Credito", width: 150 },
//  { field: "VLR_DOC", headerName: "Valor Documento", width: 150, valueGetter: currencyValueGetter, valueFormatter: currencyFormatter  }, 
  { field: "VALOR_CREDITO", headerName: "Valor Crédito", width: 170, availableAggregationFunctions: ['sum'], aggregable: true, groupable: false, valueGetter: currencyValueGetter, valueFormatter: currencyFormatter  },
  { field: "VALOR_DEBITO", headerName: "Valor Débito", width: 170, availableAggregationFunctions: ['sum'], aggregable: true, groupable: false, valueGetter: currencyValueGetter, valueFormatter: currencyFormatter  },
  
  { field: "EMITE_NFSE", headerName: "Emite NFSe", width: 150 },
//{ field: "DOC_FATURA", headerName: "Documento Fatura", width: 150 },
  { field: "FIL_FATURA", headerName: "Filial Fatura", width: 150 },
  { field: "ANO_FATURA", headerName: "Ano Fatura", width: 100 },
  { field: "NR_FATURA", headerName: "Nº Fatura", width: 100 },

//{ field: "FATURA", headerName: "Fatura", width: 150 },
//{ field: "OPERADOR", headerName: "Operador", width: 200 },
//{ field: "DT_ALTERACAO", headerName: "Data Alteração", width: 150, type: 'date' },
];

// gridColumns.js
const getAnexos: GridColDef[] = [
  {
    field: 'ARQUIVO',
    headerName: 'Arquivo',
    width: 350,
    valueGetter: (params) => params.row.NOME, // Extracts the file name
  },
  {
    field: 'download',
    headerName: 'Download',
    width: 130,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    renderCell: (params) => {
      const [open, setOpen] = useState(false);
      const [dataUrl, setDataUrl] = useState(null);
      const filename = params.row.NOME || 'download';
      const fileExtension = filename.split('.').pop()?.toLowerCase();

      const handleDownload = () => {
        if (fileExtension === 'jpg') {
          getDataUrlFromArquivoContent(params.row.ARQUIVO, filename)
            .then((url) => {
              setDataUrl(url);
              setOpen(true);
            })
            .catch((error) => {
              console.error('Error getting data URL:', error);
              alert('An error occurred while trying to preview the image.');
            });
        } else {
          convertAndDownloadFile(params.row.ARQUIVO, filename);
        }
      };

      const handleClose = () => {
        setOpen(false);
        setDataUrl(null);
      };

      const handleConfirmDownload = () => {
        setOpen(false);
        convertAndDownloadFile(params.row.ARQUIVO, filename);
      };

      return (
        <>
          <Button variant="contained" color="primary" onClick={handleDownload}>
            Download
          </Button>

          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="jpg-modal-title"
            aria-describedby="jpg-modal-description"
          >
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                maxHeight: '90vh',
                overflowY: 'auto',
                bgcolor: 'background.paper',
                border: '2px solid #000',
                boxShadow: 24,
                p: 4,
              }}
            >
              <Typography id="jpg-modal-title" variant="h6" component="h2">
                Image Preview
              </Typography>
              {dataUrl && (
                <Box sx={{ mt: 2 }}>
                  <img src={dataUrl} alt={filename} style={{ maxWidth: '100%', maxHeight: '400px' }} />
                </Box>
              )}
              <Typography id="jpg-modal-description" sx={{ mt: 2 }}>
                You are viewing the JPG file: <strong>{filename}</strong>
              </Typography>
              <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end' }}>
                <Button onClick={handleClose} color="primary" variant="contained">
                  Close
                </Button>
                <Button
                  onClick={handleConfirmDownload}
                  color="secondary"
                  variant="contained"
                  sx={{ ml: 2 }}
                >
                  Download
                </Button>
              </Box>
            </Box>
          </Modal>
        </>
      );
    },
  },
];

const theme = createTheme({ palette: { primary: { main: "#2196f3", }, secondary: { main: "#f50057", }, }, shape: { borderRadius: 8, }, });

const getTheme = (mode: PaletteMode) => createTheme({
  palette: {
    mode,
    primary: {
      main: mode === 'dark' ? '#A85DFE' : '#2196f3', 
    },
  },
});

const columnVisibilityModel = {
  FIL_ORIG: false,
  FIL_CAPA: false,
  NR_CAPA: false,
  ANO: false,
  FIL_FATURA: false,
  ANO_FATURA: false,
  CNPJ_PAG: false
};

const settings = ['Mudar senha', 'Sair'];
const getRowId = (row: RowData) => `${row.FIL_ORIG}_${row.NR_PROCESSO}/${row.ANO}`;

const StyledPaper = styled(Paper)(({ theme }) => ({
  margin: theme.spacing(2),
  padding: theme.spacing(2),
  height: '85vh',
}));

const Dashboard = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [rows, setRows] = useState<RowData[]>([]); 
  const [user, setUser] = useState<UserData | null>(null);
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [darkMode, setDarkMode] = useState(true);
  const [selectedRow, setSelectedRow] = useState<RowData | null>(null);
  const [expandedRowIds, setExpandedRowIds] = useState<string[]>([]);
  const [fetchedRows, setFetchedRows] = useState<Set<string>>(new Set());
  const [modalOpen, setModalOpen] = useState(false); 
  const [signInDialogOpen, setSignInDialogOpen] = React.useState(false);

  // dialog user
  const [diagUser, setDiagUsername] = useState('');
  const [diagPass, setDiagPassword] = useState('');
  const [diagCNPJ, setDiagCnpj] = useState('');

// dialog new password
  const [changePasswordDialogOpen, setChangePasswordDialogOpen] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  // const [aggregationModel, setAggregationModel] = React.useState<GridAggregationModel>({VLR_PEDIDO: 'sum'});
  const [aggregationModelNumerarios, setAggregationModelNumerarios] = React.useState<GridAggregationModel>({ VLR_PEDIDO: 'sum' });
  const [aggregationModelFechamentos, setAggregationModelFechamentos] = React.useState<GridAggregationModel>({ VALOR_DEBITO: 'sum', VALOR_CREDITO: 'sum' });

  const [totalValue, setTotalValue] = useState<number>(0);
  const dynamicTheme = React.useMemo(() => getTheme(darkMode ? 'dark' : 'light'), [darkMode]);
  
  const toggleDarkMode = () => { setDarkMode(!darkMode) };
  const toggleDrawer = () => { setDrawerOpen(!drawerOpen) };
  
  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => { setAnchorEl(event.currentTarget) };
  const handleMenuClose = () => { setAnchorEl(null) };
  const handleLogout = () => {  removeCookie('authToken'); navigate('/login'); navigate(0); };  
  
  const handleMenuHelp = () => { handleLogout(); window.open('/ajuda', '_blank'); };  
  
  const isMenuOpen = Boolean(anchorEl);
  const apiRef = useGridApiRef();  
 
  const navigate = useNavigate();
  const location = useLocation();
 
  const { cnpjRaw, adminRaw, empresaRaw, userRaw } = location.state || { cnpj: '', admin: 0, empresa: 0, username: '' };

  const removeCookie = (name: string): void => {
	  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
	};
	
  const handleOpenSignInDialog = () => { setSignInDialogOpen(true); };
  const handleCloseSignInDialog = () => { setSignInDialogOpen(false); };
  
  const handleConfirmSignIn = () => {
    //alert(`Username: ${diagUser}, Password: ${diagPass}, CNPJ: ${diagCNPJ}`);
	
   // Use axios to make a POST request to the /signin endpoint
    try {
        const response =  axios.post('/signin', {
            username: diagUser,
            password: diagPass,
            cnpj: diagCNPJ, // Assuming your backend expects a 'cnpj' field
			admin: 0,
			idEmpresa: 2
        });
		//console.log(response);
		alert(`Usuário ${diagUser} criado`);

        // Handle success response
        //alert(`User created: ${response.data.message}`);
        handleCloseSignInDialog();
        // Optionally reset dialog state variables here
        setDiagUsername('');
        setDiagPassword('');
        setDiagCnpj('');

    } catch (error) {
        // Handle error
        console.error('Error creating user:', error);
        // Show an error message to the user
        alert('Failed to create user.');
    }
	
    handleCloseSignInDialog();
  };
 
  const handleConfirmChangePassword = () => {
    //alert(`Username: ${diagUser}, Password: ${diagPass}, CNPJ: ${diagCNPJ}`);
	
   // Use axios to make a POST request to the /signin endpoint
    try {
        const response =  axios.post('/senha', {
            username: username,
            currentPassword: currentPassword,
            newPass: newPassword, // Assuming your backend expects a 'cnpj' field
        });
		//console.log(response);
		alert(`Usuário ${diagUser} criado`);

        // Handle success response
        //alert(`User created: ${response.data.message}`);
        handleCloseSignInDialog();
        // Optionally reset dialog state variables here
        setDiagUsername('');
        setDiagPassword('');
        setDiagCnpj('');

    } catch (error) {
        // Handle error
        console.error('Error creating user:', error);
        // Show an error message to the user
        alert('Failed to create user.');
    }
	
    handleCloseSignInDialog();
  };
   
  // Handler to open the change password dialog
  const handleOpenChangePasswordDialog = () => {
    setChangePasswordDialogOpen(true);
  };

  // Handler to close the change password dialog
  const handleCloseChangePasswordDialog = () => {
    setChangePasswordDialogOpen(false);
    // Optionally reset form fields
    setCurrentPassword('');
    setNewPassword('');
    setConfirmNewPassword('');
  };

  // Handler for the confirm action of the dialog
  const handleChangePasswordConfirm = () => {
    // Implement your password change logic here
    // This could involve validation and making a request to your backend

    if (newPassword !== confirmNewPassword) {
      alert('As senhas não coincidem. Por favor, tente novamente.');
      return;
    }

    //alert('Senha atual: ${currentPassword}, Nova senha: ${newPassword}');
    handleCloseChangePasswordDialog();

    // Reset password fields after processing (optional)
    setCurrentPassword('');
    setNewPassword('');
    setConfirmNewPassword('');
  };
  
 // Define the getCookie function with explicit type for the parameter
const getCookie = (name: string): string | undefined => {
  const nameEQ = encodeURIComponent(name) + '=';
  const cookiesArray = document.cookie.split(';');
  for (let i = 0; i < cookiesArray.length; i++) {
    let cookie = cookiesArray[i].trim();
    if (cookie.indexOf(nameEQ) === 0) {
      return decodeURIComponent(cookie.substring(nameEQ.length));
    }
  }
  return undefined; // Return undefined if the cookie is not found
};

const handleCNPJChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  let value = e.target.value;
  value = value.replace(/\D/g, ""); // Remove non-digits
  value = value.replace(/^(\d{2})(\d)/, "$1.$2");
  value = value.replace(/^(\d{2}\.\d{3})(\d)/, "$1.$2");
  value = value.replace(/\.(\d{3})(\d)/, ".$1/$2");
  value = value.replace(/(\d{4})(\d)/, "$1-$2");
  if (value.length > 18) {
    value = value.slice(0, 18);
  }
  setDiagCnpj(value);
};

  const userCookieValue = getCookie('username');
  const username = userRaw ? Number(userRaw) : Number(userCookieValue);

  const adminCookieValue = getCookie('admin');
  const admin = adminRaw ? Number(adminRaw) : Number(adminCookieValue);
  
  const cnpjCookieValue = getCookie('cnpj');
  const cnpj = cnpjRaw ? cnpjRaw : cnpjCookieValue;
    
  const empresaCookieValue = getCookie('empresa');
  const empresa = empresaRaw ? Number(empresaRaw) : empresaCookieValue;
  
  useEffect(() => {
    // User Fetch
    /*
    const fetchUserData = async () => {
      try {
        const userResponse = await axios.get<UserData>("/user/profile"); // Adjust endpoint as necessary
        setUser(userResponse.data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
	*/

    const fetchData = async () => {
      try {
        const encodedCNPJ = encodeURIComponent(cnpj);

        const params = {
          cnpj: cnpj,
          empresa: empresa,
          admin: admin,
        };

        const url = "/capas_novo";
        const response = await axios.get(url, { params });
        // console.log("API response:", response.data);

        let data = response.data;

        if (!Array.isArray(data)) {
          data = [data];
        }

        const newData = data.map((item: RowData, index: number) => {
          const id = getRowId(item);
          return {
            ...item,
            id,
          };
        });
        setRows(newData);
      } catch (error) {
        const axiosError = error as AxiosError;

        if (
          axiosError &&
          axiosError.response &&
          axiosError.response.status === 404
        ) {
          console.warn(
            "Data not found for this request:",
            axiosError.response.status
          );
          setRows([]);
        } else {
          console.error("Error fetching data:", axiosError);
          setRows([]);
        }
      } finally {
        // setLoading(false);
      }
    };

    fetchData();
    //fetchUserData();
  }, [cnpj, empresa, admin]);


// const getTreeDataPath = (row: RowData): string[] => { return []; };
// const getTreeDataPath: DataGridProProps['documentos'] = (row) => row.hierarchy;

// Example defaultInitialState object, define it according to your actual needs
const initialState: GridInitialState = useKeepGroupedColumnsHidden({
  apiRef,

});

const initialStateSum = useKeepGroupedColumnsHidden({
apiRef,
initialState: {
  aggregation: {
	model: {
	  VLR_PEDIDO: 'sum'
	},
  },
},
});

const getDetailPanelContent = React.useCallback<any>(({ row }: { row: any }) => <DetailPanelContent row={row} />, []);

const getDetailPanelHeight = React.useCallback(() => 200, []);

const DetailPanelContent: React.FC<{ row: any }> = ({ row }) => {
  const [loading, setLoading] = useState(true);	
  const [documentos, setDocumentos] = useState<ChildData[]>([]);
  const [containers, setContainers] = useState<ChildData[]>([]);
  const [cargas, setCargas] = useState<ChildData[]>([]);
  const [status, setStatus] = useState<ChildData[]>([]);
  const [numerarios, setNumerarios] = useState<ChildData[]>([]);
  const [fechamentos, setFechamentos] = useState<ChildData[]>([]);
  const [anexos, setAnexos] = useState<ChildData[]>([]);
  
  const processIdentifier = `${row.FIL_ORIG}/${row.NR_PROCESSO}/${row.ANO}`;
  
  useEffect(() => {
    if (!row || !row.FIL_ORIG || !row.NR_PROCESSO || !row.ANO) {
      console.error("Row data is missing:", row);
      return;
    }
    setLoading(true);
    const fetchData = async (
      endpoint: string,
      setData: React.Dispatch<React.SetStateAction<ChildData[]>>
    ) => {
      try {
        const url = `/${endpoint}/${processIdentifier}`;
        const response = await axios.get(url);
        let data = response.data;
        if (!Array.isArray(data)) {
          data = data ? [data] : [];
        }
        const dataWithId = data.map((item: ChildData, index: number) => ({
          ...item,
          id: `${processIdentifier}_${index}`,
        }));
        setData(dataWithId);
      } catch (error: any) {
        const axiosError = error as AxiosError;
        //console.error("Error fetching ${endpoint} data:", axiosError?.message || error);
        if (axiosError.response?.status === 204) {
          setData([]);
        } else {
          //console.error(`Error fetching ${endpoint} data:`, axiosError);
          setData([]);
        }
      } finally {
        setLoading(false);
      }
    };
    if (row && row.FIL_ORIG && row.NR_PROCESSO && row.ANO) {
      fetchData("documentos", setDocumentos);
      fetchData("containers", setContainers);
      fetchData("cargas", setCargas);
      fetchData("status", setStatus);
      fetchData("numerarios", setNumerarios);
      fetchData("fechamentos", setFechamentos);
	  fetchData("anexos", setAnexos);
    }
  }, [row]);


  return (
 <>
  <Box sx={{ mb: 2, p: 2, width: '100%' }}>
    <Typography variant="h6" gutterBottom>
      Aduaneiro
    </Typography>
    {loading ? (
      <Typography>Carregando...</Typography>
    ) : documentos.length === 0 ? (
      <Typography>Sem dados disponíveis.</Typography>
    ) : (
      <DataGrid
        rows={documentos}
        columns={getAduaneiro}
	    columnVisibilityModel={columnVisibilityModel}
		density="compact"
        disableRowSelectionOnClick
        autoHeight
        hideFooter={true}
      />
    )}
  </Box>

  <Box sx={{ mb: 2, p: 2, width: '100%' }}>
    <Typography variant="h6" gutterBottom>
      Containers
    </Typography>
    {loading ? (
      <Typography>Carregando...</Typography>
    ) : containers.length === 0 ? (
      <Typography>Sem dados disponíveis.</Typography>
    ) : (
      <DataGrid
        rows={containers}
        columns={getContainer}
		columnVisibilityModel={columnVisibilityModel}
		density="compact"
        disableRowSelectionOnClick
        autoHeight
        hideFooter={true}
      />
    )}
  </Box>

  <Box sx={{ mb: 2, p: 2, width: '100%' }}>
    <Typography variant="h6" gutterBottom>
      Carga Solta
    </Typography>
    {loading ? (
      <Typography>Carregando...</Typography>
    ) : cargas.length === 0 ? (
      <Typography>Sem dados disponíveis.</Typography>
    ) : (
      <DataGrid
        rows={cargas}
        columns={getCargaSolta}
		columnVisibilityModel={columnVisibilityModel}
		density="compact"
        disableRowSelectionOnClick
        autoHeight
        hideFooter={true}
      />
    )}
  </Box>

  <Box sx={{ mb: 2, p: 2, width: '100%' }}>
    <Typography variant="h6" gutterBottom>
      Status
    </Typography>
    {loading ? (
      <Typography>Carregando...</Typography>
    ) : status.length === 0 ? (
      <Typography>Sem dados disponíveis.</Typography>
    ) : (
      <DataGrid
        rows={status}
        columns={getStatus}
		columnVisibilityModel={columnVisibilityModel}
		density="compact"
        disableRowSelectionOnClick
        autoHeight
        hideFooter={true}
      />
    )}
  </Box>  
  
  <Box sx={{ mb: 2, p: 2, width: '100%' }}>
    <Typography variant="h6" gutterBottom>
      Numerários
    </Typography>
    {loading ? (
      <Typography>Carregando...</Typography>
    ) : numerarios.length === 0 ? (
      <Typography>Sem dados disponíveis.</Typography>
    ) : (
      <DataGridPremium
        rows={numerarios}
        columns={getNumerarios}
		columnVisibilityModel={columnVisibilityModel}
		density="compact"
        disableRowSelectionOnClick
        autoHeight
        hideFooter={true}
	    // initialState={initialStateSum}	
		aggregationModel={aggregationModelNumerarios}
        onAggregationModelChange={(newModel) => setAggregationModelNumerarios(newModel)}
		initialState={{
          aggregation: {
            model: {
              VLR_PEDIDO: 'sum'
            }
          }
        }}
      />	
    )}
  </Box>  
  
  <Box sx={{ mb: 2, p: 2, width: '100%' }}>
    <Typography variant="h6" gutterBottom>
      Faturamentos
    </Typography>
    {loading ? (
      <Typography>Carregando...</Typography>
    ) : fechamentos.length === 0 ? (
      <Typography>Sem dados disponíveis.</Typography>
    ) : (
      <DataGridPremium
        rows={fechamentos}
        columns={getFaturamentos}
		columnVisibilityModel={columnVisibilityModel}
		density="compact"
        disableRowSelectionOnClick
        autoHeight
        hideFooter={true}
		// initialState={initialStateSum}	
		aggregationModel={aggregationModelFechamentos}
        onAggregationModelChange={(newModel) => setAggregationModelFechamentos(newModel)}
		initialState={{
          aggregation: {
            model: {
              VALOR_DEBITO: 'sum',
			  VALOR_CREDITO: 'sum'
            }
          }
        }}
      />
    )}
  </Box> 

  <Box sx={{ mb: 2, p: 2, width: '100%' }}>
    <Typography variant="h6" gutterBottom>
      Anexos
    </Typography>
    {loading ? (
      <Typography>Carregando...</Typography>
    ) : anexos.length === 0 ? (
      <Typography>Sem dados disponíveis.</Typography>
    ) : (
	  <DataGrid
        rows={anexos}
        columns={getAnexos}
		columnVisibilityModel={columnVisibilityModel}
		density="compact"
        disableRowSelectionOnClick
        autoHeight
        hideFooter={true}
      />
    )}
  </Box>  
  
</>

  );
};

  return (
<ThemeProvider theme={dynamicTheme}>
  <CssBaseline />
  <AppBar
    position="static"
    sx={{ backgroundColor: dynamicTheme.palette.primary.main }}
  >
    <Toolbar>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={toggleDrawer}
      >
        <MenuIcon />
      </IconButton>
      <Typography variant="h6" style={{ flexGrow: 1 }}>
        Comissária
      </Typography>
      <IconButton onClick={toggleDarkMode} color="inherit">
        <Brightness4Icon />
      </IconButton>
      {user ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="subtitle1" sx={{ marginRight: "10px" }}>
              {user.name}
            </Typography>
            <IconButton
              edge="end"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <Avatar src={user.photo} alt="Profile Avatar" />
            </IconButton>
          </div>
        ) : (
          <IconButton
            edge="end"
            onClick={handleProfileMenuOpen}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
        )}
		
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={isMenuOpen}
          onClose={handleMenuClose}
        >
		{
		  // Check if `admin` is not greater than 0 (meaning admin is 0 or a non-admin user)

		  !(admin > 0) && (
			<MenuItem onClick={handleOpenChangePasswordDialog}>Mudar Senha</MenuItem>
		  )
		}

		<Dialog open={changePasswordDialogOpen} onClose={handleCloseChangePasswordDialog}>
		  <DialogTitle>Alterar senha</DialogTitle>
		  <DialogContent>
			<DialogContentText>
			   Para alterar sua senha, digite sua senha atual seguida pela nova senha.
			</DialogContentText>
			<TextField
			  autoFocus
			  margin="dense"
			  id="current-password"
			  label="Senha atual"
			  type="password"
			  fullWidth
			  variant="standard"
			  value={currentPassword}
			  onChange={(e) => setCurrentPassword(e.target.value)}
			/>
			<TextField
			  margin="dense"
			  id="new-password"
			  label="Nova senha"
			  type="password"
			  fullWidth
			  variant="standard"
			  value={newPassword}
			  onChange={(e) => setNewPassword(e.target.value)}
			/>
			<TextField
			  margin="dense"
			  id="confirm-new-password"
			  label="Confirmar nova senha"
			  type="password"
			  fullWidth
			  variant="standard"
			  value={confirmNewPassword}
			  onChange={(e) => setConfirmNewPassword(e.target.value)}
			/>
		  </DialogContent>
		  <DialogActions>
			<Button onClick={handleCloseChangePasswordDialog}>Cancelar</Button>
			<Button onClick={handleChangePasswordConfirm}>Confirmar</Button>
		  </DialogActions>
		</Dialog>
		 
		  <MenuItem onClick={handleMenuHelp}>Ajuda</MenuItem>
          <MenuItem onClick={handleLogout}>Sair</MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  <StyledPaper>
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <Typography variant="h6" component="h2" color="secondary">
        Capas
      </Typography>
      <div>	

	{
	  // Assuming `admin` is a state or prop that holds a number or boolean
	  // Check if `admin` is true or greater than 0

	  admin > 0 && (
		<Button
		  variant="outlined"
		  color="secondary"
		  startIcon={<PersonAddIcon />}
		  onClick={handleOpenSignInDialog}
		>
		  Novo Usuário
		</Button>
	  )
	}

      {/* Sign In Dialog */}
      <Dialog open={signInDialogOpen} onClose={handleCloseSignInDialog}>
        <DialogTitle>Adicionar usuário</DialogTitle>
        <DialogContent>
          <DialogContentText>
             Adicione dados de login e senha para gerar novo usuário
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="username"
            label="Login"
            type="text"
            fullWidth
            variant="standard"
			value={diagUser}
			onChange={(e) => setDiagUsername(e.target.value)}
          />
          <TextField
            margin="dense"
            id="password"
            label="Senha"
            type="password"
            fullWidth
            variant="standard"
			value={diagPass}
			onChange={(e) => setDiagPassword(e.target.value)}
          />
          <TextField
            margin="dense"
            id="cnpj"
            label="CNPJ"
            type="text"
            fullWidth
            variant="standard"
			value={diagCNPJ}
			// onChange={(e) => setDiagCnpj(e.target.value)}
			onChange={handleCNPJChange}
          />   
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSignInDialog}>Cancelar</Button>
          <Button onClick={handleConfirmSignIn}>Confirmar</Button>
        </DialogActions>
      </Dialog>

      </div>
    </div>
    <Box sx={{ height: '75vh', width: "100%" }}>
      <DataGridPremium
        rows={rows}
        columns={getCapas}
        getRowId={getRowId}
        apiRef={apiRef}
        disableRowSelectionOnClick
        initialState={initialState}
        // treeData={true}
        // getTreeDataPath={(row) => row.path}
        rowThreshold={0}
        //	getDetailPanelHeight={getDetailPanelHeight}
        getDetailPanelContent={getDetailPanelContent}
      />
    </Box>
  </StyledPaper>
  {/* 
	  <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer}>
		<List>
		  <ListItem button onClick={toggleDarkMode}>
			<ListItemIcon>
			  <Brightness4Icon />
			</ListItemIcon>
			<ListItemText primary="Dark Mode" />
			<Switch
			  checked={darkMode}
			  onChange={toggleDarkMode}
			  name="darkMode"
			  color="default"
			  />
		  </ListItem>
		</List>
	  </Drawer>
	  */}
  {/* Main content */}
  <main>{/* Your main content */}</main>
  {/* Modal for displaying detailed information */}
  <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
    <Box sx={{ width: 400, bgcolor: "background.paper", p: 2 }}>
      <Typography variant="h6" gutterBottom>
        Detailed Information
      </Typography>
      {selectedRow && (
        <div>
          {/* Display detailed information here */}
          <Typography variant="body1">
            FIL_ORIG: {selectedRow.FIL_ORIG}
          </Typography>
          <Typography variant="body1">
            NR_PROCESSO: {selectedRow.NR_PROCESSO}
          </Typography>
          {/* Add more fields as needed */}
        </div>
      )}
    </Box>
  </Modal>
</ThemeProvider>

  );
};

export default Dashboard;