// utils.js
export function convertAndDownloadFile(arquivoContent, filename) {
  if (typeof filename !== 'string' || !filename.includes('.')) {
    alert('Nome de arquivo inválido. Por favor, forneça um nome de arquivo com extensão (e.g., "arquivo.jpg").');
    return;
  }

  // Função auxiliar para determinar o tipo MIME com base na extensão do arquivo
  const getMimeType = (filename) => {
    const extension = filename.split('.').pop().toLowerCase();
    const mimeTypes = {
      jpg: 'image/jpeg',
      jpeg: 'image/jpeg',
      png: 'image/png',
      gif: 'image/gif',
      pdf: 'application/pdf',
      txt: 'text/plain',
      // Adicione mais tipos MIME conforme necessáriСo
    };
    return mimeTypes[extension] || 'application/octet-stream';
  };

  try {
    let blob;

    console.log('Tipo de arquivoContent:', typeof arquivoContent);
    console.log('Conteúdo de arquivoContent:', arquivoContent);

    if (typeof arquivoContent === 'object' && arquivoContent.data && Array.isArray(arquivoContent.data)) {
      console.log('arquivoContent (códigos): ' + arquivoContent.data);

      // Reconstruir a string Base64 a partir dos códigos de caracteres
      const base64String = String.fromCharCode(...arquivoContent.data);
      console.log('Base64 reconstruído: ' + base64String);

      // Decodificar a string Base64 para obter a string binária
      const binaryString = atob(base64String);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);

      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }

      console.log('Conteúdo binário decodificado:', bytes);

      // Criar o Blob a partir dos bytes decodificados
      blob = new Blob([bytes], { type: getMimeType(filename) });
      console.log('Blob criado:', blob);
      
    } else if (typeof arquivoContent === 'string') {
      // Supondo que arquivoContent seja uma string Base64
      // Remove o prefixo data URL se existir
      const base64String = arquivoContent.startsWith('data:')
        ? arquivoContent.substring(arquivoContent.indexOf(',') + 1)
        : arquivoContent;

      console.log('Base64String: ' + base64String);

      // Decodifica a string Base64
      const binaryString = atob(base64String);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);

      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }

      console.log(`Conteúdo binário:\n${binaryString}`);

      blob = new Blob([bytes], { type: getMimeType(filename) });

      console.log('Bytes decodificados: ' + bytes);
      console.log('Blob criado:', blob);
    } else {
      alert('Formato de arquivo não suportado.');
      return;
    }

    // Cria uma URL para o Blob
    const blobUrl = URL.createObjectURL(blob);
    console.log('blobUrl: ' + blobUrl);

    // Cria um elemento <a> temporário para iniciar o download
    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = filename;

    // Adiciona o link ao DOM, clica nele e remove
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Libera a URL do Blob
    URL.revokeObjectURL(blobUrl);

    console.log(`Arquivo "${filename}" baixado com sucesso.`);
  } catch (error) {
    console.error('Erro ao converter e baixar o arquivo:', error);
    alert('Ocorreu um erro ao tentar baixar o arquivo. Por favor, tente novamente.');
  }
}

// utils.js
export function getDataUrlFromArquivoContent(arquivoContent, filename) {
  const getMimeType = (filename) => {
    const extension = filename.split('.').pop().toLowerCase();
    const mimeTypes = {
      jpg: 'image/jpeg',
      jpeg: 'image/jpeg',
      png: 'image/png',
      gif: 'image/gif',
      pdf: 'application/pdf',
      txt: 'text/plain',
      // Add more MIME types as needed
    };
    return mimeTypes[extension] || 'application/octet-stream';
  };

  return new Promise((resolve, reject) => {
    try {
      let blob;

      if (typeof arquivoContent === 'object' && arquivoContent.data && Array.isArray(arquivoContent.data)) {
        const base64String = String.fromCharCode(...arquivoContent.data);
        const binaryString = atob(base64String);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);

        for (let i = 0; i < len; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }

        blob = new Blob([bytes], { type: getMimeType(filename) });
      } else if (typeof arquivoContent === 'string') {
        const base64String = arquivoContent.startsWith('data:')
          ? arquivoContent.substring(arquivoContent.indexOf(',') + 1)
          : arquivoContent;

        const binaryString = atob(base64String);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);

        for (let i = 0; i < len; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }

        blob = new Blob([bytes], { type: getMimeType(filename) });
      } else {
        alert('Unsupported file format.');
        reject('Unsupported file format.');
        return;
      }

      const reader = new FileReader();
      reader.onloadend = function () {
        resolve(reader.result); // This is the data URL
      };
      reader.onerror = function (error) {
        reject(error);
      };
      reader.readAsDataURL(blob);
    } catch (error) {
      console.error('Error converting file to data URL:', error);
      reject(error);
    }
  });
}
